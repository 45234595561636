
import { Component, Vue } from 'vue-property-decorator'
import { ElementDetail } from '@/types/patrol'

@Component
export default class ElementDetailPage extends Vue {
  private cycleList = ['日', '周', '月']

  private detail: ElementDetail = {
    elementName: '',
    riskLevelName: '',
    standardList: [],
    corpFrequency: 0,
    corpFrequencyType: 1,
    cooperateFrequency: 0,
    cooperateFrequencyType: 1,
    controlMeasures: '',
    emergencyDisposal: ''
  }

  get elementId () {
    return this.$route.params.elementId
  }

  created (): void {
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.patrol.selectElementByElementId, {
      elementId: this.elementId
    }).then(res => {
      this.detail = res || {}
    })
  }
}
